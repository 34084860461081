<template>
	<div v-if="!loading" class="space-owners content-padding">
        <div class="row">
            <div class="col-12">
                <BaseCard :padding="'25px'">
                    <h6>
                        {{ labels.current_owners }}:
                    </h6>
                    <div class="owners">
                        <span v-for="(owner, index) in singleSpace.owners" :key="`owner-${index}`" class="owner">{{ owner.username }}</span>
                    </div>
                    <button @click="$router.push({ name: 'space-owners-manage', params: { id: $route.params.id } })" class="btn btn-primary">
                        {{ labels.manage_owners }}
                    </button>
                </BaseCard>
            </div>
        </div>
        <router-view name="space_owners" />
	</div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'SpaceOwners',
        components: {
            BaseCard: () => import("@/components/Cards/BaseCard")
        },
        props: {
            loading: {
                type: Boolean,
                default: false
            },
            localData: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            spaceFields: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {}
        },
        computed: {
            ...mapGetters('helper', ['labels']),
            ...mapState('space', ['singleSpace'])
        },
        methods: {

        },
        mounted() {
            
        }
    }
</script>

<style lang='scss' scoped>
    h6 { 
        display: flex;
        flex-wrap: wrap;
    }

    .owners {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -1rem;
    }

    .owner {
        background-color: #b0b0b0;
        border-radius: 5px;
        margin-left: 1rem;
        color: #fff;
        padding: 0.5rem;
        margin-bottom: 1rem;
        display: block;
    }
</style>